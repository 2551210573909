const cookie = () => {
  let btnCookie = $("#cookie-accept")

  const hideCookie = localStorage.getItem("hidde-cookie");

  if (!hideCookie) {
    $(".cookie").removeClass("cookie--hide");
  }
  
  // $(".cookie .cookie-link").on("click", () => {
  //   btnCookie.prop("disabled", false)
  // })

  btnCookie.on("click", () => {
    localStorage.setItem("hidde-cookie", true);

    $('.cookie').remove();
  })
}

export default cookie;