const footer = () => {
  $("[data-address]").on("click", function() {
    const address = $(this).text().trim();

    console.log(address);

    const yandexMapsUrl = `https://yandex.ru/maps/?text=${encodeURIComponent(address)}`;

    window.open(yandexMapsUrl, "_blank");
  })
}

export default footer;