export const progres = () => {
  $("[data-presentation]").on("click", function() {

    let link =  $("#modal-presentation .link-presentation");

    let input = $("#modal-presentation .form__group--hidden .input__input");

    const presentationUrl = $(this).attr("data-presentation-url");

    const presentationValue = $(this).attr("data-presentation");
  
    link.attr("href", presentationUrl);

    input.val(presentationValue);
  });
};

